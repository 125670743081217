import React from "react";
import "./Contact.css";
import ContactImage from '../../assets/contact/contact.jpg'
import { ContactForm } from "./ContactForm";

export default function Contact() {



    return (
        <section id='contact'>
            <div className="container">
                <div className="contact_wrapper">
                    <div className="contact_col">
                        <div className="contact_image">
                            <img src={ContactImage} alt='Contact' />
                        </div>
                    </div>
                    <div className="contact_col">
                        <h3>Nous contacter</h3>
                        <p>Si je vous ai convaincu, il est temps de prendre contact avec moi au <a href="tel:+32479820993">0479 82 09 93</a> ou par mail via <a href="mailto:contact@sp-it.be">contact@sp-it.be</a>. Vous pouvez aussi compléter le formulaire ci-dessous. <br/></p>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </section>
    )
}