import React from 'react'
import './About.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faUser, faComputer, faUserClock, faHouseLaptop, faPhone } from '@fortawesome/free-solid-svg-icons'
import AboutImage from '../../assets/about/about-image.jpg'

export default function About() {

    const faCodeIcon = <FontAwesomeIcon icon={faCode} />
    const faSupportIcon = <FontAwesomeIcon icon={faUser} />
    const faComputerIcon = <FontAwesomeIcon icon={faComputer} />
    const faUserIcon = <FontAwesomeIcon icon={faUserClock} />
    const faHouseIcon = <FontAwesomeIcon icon={faHouseLaptop} />
    const faPhoneIcon = <FontAwesomeIcon icon={faPhone} />

    return(
        <>
        <section id="about">
            <div className='container'>
                <div className='title_headling'>
                    <h3>A propos de nous</h3>
                    <p>Je suis principalement un expert IT qui a débuté dans le support informatique sur un parc informatique de pas moins de 22.000 ordinateurs. A côté de ça, je n'ai jamais cessé de développer des applications et site web pour aider dans les tâches et pour me former. Je tenais à apprendre en autodidacte dans ce monde informatique qui m'a bercé.</p>
                </div>
                <div className='about_box_wrapper'>
                    <div className='about_box'>
                        <div className='about_icon red_icon'>
                            {faCodeIcon}
                        </div>
                        <div className='about_content'>
                            <h5>Développement Logiciel et Web</h5>
                            <p>Je souhaite vous aider à développer vos sites web complets et dynamiques ou vos logiciels.</p>
                        </div>
                    </div>
                    <div className='about_box'>
                        <div className='about_icon green_icon'>
                            {faSupportIcon}
                        </div>
                        <div className='about_content'>
                            <h5>Support Informatique</h5>
                            <p>Comme je le fais pour mon métier principal, je veux étendre cela à mon entreprise et vous apporter le support nécessaire en cas de soucis avec votre ordinateur personnel.</p>
                        </div>
                    </div>
                    <div className='about_box'>
                        <div className='about_icon blue_icon'>
                            {faComputerIcon}
                        </div>
                        <div className='about_content'>
                            <h5>Matériel Informatique</h5>
                            <p>Je peux monter et installer vos machines ou vous conseiller et vendre les produits informatiques répondant à vos demandes.</p>
                        </div>
                    </div>
                </div>
                <div className='about_box_details'>
                    <div className='about_col'>
                        <div className='about_image'>
                            <img src={AboutImage} alt='A propos'/>
                        </div>
                        <div className='img_info_box'>
                            <h6 className='img_info_title'>Passionné avant tout.</h6>
                            <p>J'ai décidé de me lancer car c'est avant tout une passion et j'aimerais pouvoir un jour gagner ma vie par rapport à celle-ci.<br/>Je me forme en continue et tente de relever les défis qu'on me lance pour répondre aux mieux à vos attentes!</p>
                            <a href='callto:+32479820993'>{faPhoneIcon} <span>0479/82.09.93</span></a>
                        </div>
                    </div>
                    <div className='about_col more_space'>
                        <h3>Je développe des compétences</h3>
                        <p>Je travaille différents langages notamment le JavaScript (NodeJS, Angular (débutant), React.js, etc), le HTML et le CSS, le C# et le Powershell. Mais je continue chaque jour de me renseigner et d'apprendre des langages.</p>

                        <div className='grid_info'>
                            <div className='icon'>{faUserIcon}</div>
                            <div className='details'>
                                <h4>Software</h4>
                                <p>Selon chaque demande, je me perfectionne et je relève le défi pour répondre le mieux aux attentes.</p>
                            </div>
                        </div>
                        <div className='grid_info'>
                            <div className='icon'>{faHouseIcon}</div>
                            <div className='details'>
                                <h4>Hardware</h4>
                                <p>Je me tiens informé des sorties et compare les différents modèles existants pour toujours fournir le meilleur conseil matériel.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}