import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Slider.css';
import slider1 from '../../assets/blog/slider1.png'
import slider2 from '../../assets/blog/slider2.png'
import slider3 from '../../assets/blog/slider3.png'

const CarouselPage = () => {
    return (
        <section className='blog_image'>
        <Carousel
        statusFormatter={(current, total) => `${current} sur ${total}`}
        >
            <div>
                <img src={slider1} alt="Site officiel François Leclercq"/>
                <p className="legend"><a href="https://flosteo.be" target="_blank" rel="noreferrer">https://flosteo.be</a></p>
            </div>
            <div>
                <img src={slider2} alt="Carte de visite SPIT"/>
                <p className="legend"><a href="https://links.sp-it.be" target="_blank" rel="noreferrer">https://links.sp-it.be</a></p>
            </div>
            <div>
                <img src={slider3} alt="Site récap François Leclercq"/>
                <p className="legend"><a href="https://recap.flosteo.be" target="_blank" rel="noreferrer">https://recap.flosteo.be</a></p>
            </div>
        </Carousel>
        </section>
    );
};
export default CarouselPage