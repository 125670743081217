import React from "react";
import './Blog.css';
import Slider from "./Slider";

export default function Blogs() {
    return (
        <section id='blog'>
            <div className='container'>
                <div className='blog_wrapper'>
                    <div>
                        <h3>Portfolio</h3>
                        <p>Retrouvez les différents projets sur lesquels j'ai déjà pu travailler.</p>
                        {/*<div className="btn_wrapper">
                            <a className="btn" href='/assets/blog/porfolio.html'>Voir plus</a>
                        </div>*/}
                        <Slider />
                    </div>
                    {/*<div className="blog_col">
                        <div className="blog_image">
                            <img src={BlogImage} alt='Blog'/>
                        </div>
                    </div>*/}
                </div>
            </div>
        </section>
    )
}