import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser'

export const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_q8ouz1q','template_w4q7zqg', form.current, {
                from_name: name,
                from_email: email,
                message: message,
                publicKey: 'FvMeO-ZOgP6ilz1Mk',
            })
            .then(
                () => {
                    console.log('Mail envoyé')
                    setName("");
                    setEmail("");
                    setMessage("");
                },
                (error) => {
                    console.log('Mail non envoyé', error.text)
                }
            )
    }

    return (
        <form ref={form} onSubmit={sendEmail}>
            <div className="input_wrapper">
                <input 
                    name="from_name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    type="text" 
                    className='form-control' 
                    placeholder='Nom' 
                    autoComplete="off"/>
            </div>
            <div className="input_wrapper">
                <input 
                    name="from_email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    type="email" 
                    className='form-control' 
                    placeholder='Email' 
                    autoComplete="off"/>
            </div>
            <div className="input_wrapper">
                <textarea
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    className='form-control'
                    placeholder='Message'
                />
            </div>
            <div className="btn_wrapper">
                <button type="submit" className="btn">Envoyer</button>
            </div>
        </form>
    );
};