import React from 'react'
import './Home.css'
import { Link } from 'react-scroll';
import BannerImage from '../../assets/banner/banner-image.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEarListen, faBookOpen } from '@fortawesome/free-solid-svg-icons'


export default function Home() {

    const faEarIcon = <FontAwesomeIcon icon={faEarListen} />
    const faBookIcon = <FontAwesomeIcon icon={faBookOpen} />
    const faClockIcon = <FontAwesomeIcon icon={faClock} />

    return (
        <>
        <section id='home' className='home'>
            <div className='banner_image'></div>
            <div className='container'>
                <div className='banner_outer'>
                    <div className='col'>
                        <h3 className='title'>
                            Spit votre <span>solution IT</span>
                        </h3>
                        <p>SPIT est une entreprise personne physique belge gérée par Steven PAPIER. Je propose de développer votre site web, vos logiciels ou encore de vous monter et/ou vendre votre matériel informatique.</p>
                        <div className='btn_wrapper'>
                            <Link to='services' spy={true} smooth={true}>
                                <button className='btn'>Nos services</button>
                            </Link>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='sub_banner_image'>
                            <img src={BannerImage} alt='Bannière'/>
                        </div>
                        <div className='banner_style_1'>
                            {faEarIcon}
                            <h4>À l'écoute</h4>
                        </div>
                        <div className='banner_style_1 banner_style_2'>
                            {faBookIcon}
                            <h4>En formation continu</h4>
                        </div>
                        <div className='banner_style_1 banner_style_3'>
                            {faClockIcon}
                            <h4>Disponible</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}