import React from 'react'
import './Services.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode, faChartBar, faCopy, faMarker, faUserGear, faCoins } from '@fortawesome/free-solid-svg-icons'

export default function Services() {

    const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faCopyIcon = <FontAwesomeIcon icon={faCopy} />
    const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />

    return(
        <div id="services">
            <div className='container'>
                <div className='title_headling'>
                    <h3>Quelles sont les services offerts par SPIT ?</h3>
                    <p>Nous offrons du support informatique, de la vente et du conseil en matériel ICT, du montage et surtout du développement Web et Logiciel pour votre Petite, Moyenne ou Grande Entreprise.</p>
                </div>
                <div className='service_wrapper'>
                    <div className='service_box'>
                        <div className='service_icon blue_icon'>{faLaptopCodeIcon}</div>
                        <h4 className='number'>01</h4>
                        <div className='service_content'>
                            <h5>Développement web</h5>
                            <p>Pour votre image, je vous aide à développer des sites vitrines ou des sites plus spécifiques. Prenez contact avec moi afin d'en discuter et d'établir un cahier de travail.</p>
                            {/*<a href='/' className='read'>En savoir plus</a>*/}
                        </div>
                    </div>
                    <div className='service_box'>
                        <div className='service_icon blue_icon'>{faChartBarIcon}</div>
                        <h4 className='number'>02</h4>
                        <div className='service_content'>
                            <h5>Développement Logiciel</h5>
                            <p>Je peux automatiser vos tâches journalières ou développer des logiciels qui permettront de vous aider dans votre travail quotidien pour vous faciliter la vie.</p>
                            {/*<a href='/' className='read'>En savoir plus</a>*/}
                        </div>
                    </div>
                    <div className='service_box'>
                        <div className='service_icon blue_icon'>{faCopyIcon}</div>
                        <h4 className='number'>03</h4>
                        <div className='service_content'>
                            <h5>Support IT</h5>
                            <p>Une problème avec votre ordinateur, avec un programme ou avec un site web ? Comme j'aime le dire : Il n'y a pas de problème, que des solutions. Et la vôtre, c'est SPIT !</p>
                            {/*<a href='/' className='read'>En savoir plus</a>*/}
                        </div>
                    </div>
                    <div className='service_box'>
                        <div className='service_icon blue_icon'>{faMarkerIcon}</div>
                        <h4 className='number'>04</h4>
                        <div className='service_content'>
                            <h5>Conseils IT</h5>
                            <p>Nous pouvons prendre ensemble le temps de voir ce qui conviendra le mieux pour vous ou votre entreprise concernant les programmes, le matériel, la mise à jour de vos produits, etc</p>
                            {/*<a href='/' className='read'>En savoir plus</a>*/}
                        </div>
                    </div>
                    <div className='service_box'>
                        <div className='service_icon blue_icon'>{faUserGearIcon}</div>
                        <h4 className='number'>05</h4>
                        <div className='service_content'>
                            <h5>Vente de matériel</h5>
                            <p>Je peux vous vendre du matériel neuf obtenu auprès de fournisseurs reconnus et en lesquels j'ai confiance et pour lequel j'aurais fait un travail en amont ou vous vendre du matériel d'occasion.</p>
                            {/*<a href='/' className='read'>En savoir plus</a>*/}
                        </div>
                    </div>
                    <div className='service_box'>
                        <div className='service_icon blue_icon'>{faCoinsIcon}</div>
                        <h4 className='number'>06</h4>
                        <div className='service_content'>
                            <h5>Montage</h5>
                            <p>Je serais ravi de monter vos ordinateurs ou parc informatique. Je peux faire cette session avec vous afin que vous puissiez suivre chaque étape de montage de votre machine afin de la comprendre.</p>
                            {/*<a href='/' className='read'>En savoir plus</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}